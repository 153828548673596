import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import moment from 'moment';

import { Text, CustomMenu } from 'components';
import { useCustomForm } from 'hooks';

import HealthDetail from '../../patientDetails/components/HealthDetail';
import { sortByDateFunction } from 'utils/sort';

import RiskAssessmentCompact from 'components/RiskAssessment/RiskAssessmentCompact/RiskAssessmentCompact';
import BmiVisual from '../../patientPages/bmiPage/components/BmiVisual';
import BpVisual from '../../patientPages/bloodPressurePage/components/BpVisual';
import OxyVisual from '../../patientPages/oxyPage/components/OxyVisual';

import ResultsTable from 'features/LabResultsTable/ResultsTable';
import tinyBlueSvg from 'assets/new/tinyBlueRiverrLogo.svg';

import patientDashboardPublicApi from 'api/patientDashboardPublic';
import medicalProfileProviderApi from 'api/patientOverview/clientApi/medicalProlileProviderApi';
import IdClientService from 'api/client/client';

import cardioSvg from 'assets/new/patientApp/cardiovascular.svg';
import chdSvg from 'assets/new/patientApp/chd.svg';
import diabetesSvg from 'assets/new/patientApp/diabetes.svg';
import hypertensionSvg from 'assets/new/patientApp/hypertension.svg';
import naflSvg from 'assets/new/patientApp/nafl.svg';
import kidneySvg from 'assets/new/patientApp/kidney.svg';
import { vitalSchema } from 'pages/patientDetails/schema';

import history from '../../../history';
import './patientDashboard.scss';

const PatientDashboard = () => {
  const [patientDetails, setPatientDetails] = useState(null);
  const [results, setResults] = useState([]);
  const [isSearching, setSearching] = useState(true);
  const [isEditHealthDetails, setIsEditHealthDetails] = useState(false);
  const [fetchingPatient, setFetchingPatient] = useState(true);
  const [paginationInfo, setPaginationInfo] = useState({
    total: 0,
    totalPages: 0,
    limit: 0,
  });
  const [patientPhoto, setPatientPhoto] = useState(null);
  const [medProfile, setMedProfile] = useState(null);
  const [healthDetails, setHealthDetails] = useState([]);
  const [editedHealthDetails, setEditedHealthDetails] = useState({});
  const [riskModelsSummary, setriskModelsSummary] = useState(null);
  const [riskAssessmentHistory, setRiskAssessmentHistory] = useState(null);
  const [hasRiskModelsValue, setHasRiskModelsValue] = useState(false);
  const [latestBmi, setLatestBmi] = useState({});
  const [latestBloodPressure, setLatestBloodPressure] = useState({});
  const [latestPulseOx, setLatestPulseOx] = useState({});
  const [patClientGroupDetails, setPatClientGroupDetails] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [selectedTab, setSelectedTab] = useState(null);

  const getLatestVital = (vitalList = []) => {
    if (!vitalList.length) return null;
    const sortedList = vitalList.sort(sortByDateFunction);
    return sortedList[0];
  };

  const {
    Controller,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useCustomForm(vitalSchema, {
    weight: '',
    height: '',
    systolicUpperNumber: '',
    diastolicLowerNumber: '',
    heartRate: '',
    pulseOximeter: '',
    perfusionIndex: '',
  });

  useEffect(() => {
    getUrlSeachParams();
  }, []);

  useEffect(() => {
    if (!medProfile) return;
    setLatestBmi(getLatestVital(medProfile?.bmiList));
    setLatestBloodPressure(getLatestVital(medProfile?.bloodPressureList));
    setLatestPulseOx(getLatestVital(medProfile?.pulseOxList));
  }, [medProfile]);

  useEffect(() => {
    const token = localStorage.getItem('AUTH_TOKEN');
    if (!!token) fetchPatient();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('AUTH_TOKEN');
    if (!!token && !!patientDetails) fetchResults();
  }, [patientDetails]);

  const getUrlSeachParams = () => {
    let urlSearchParams = new URLSearchParams(window.location.search);

    const token = urlSearchParams.get('token');

    localStorage.setItem('AUTH_TOKEN', JSON.stringify({ token }));
  };

  const fetchResults = async (searchParams = {}) => {
    if (!patientDetails?.id) return;
    setSearching(true);

    let finalParams = {
      ...searchParams,
      limit: 1000,
    };
    const resultsResponseData = await fetchNormalResults(finalParams);
    if (resultsResponseData) {
      setResults(resultsResponseData?.results);
      const { page, totalPages, limit } = resultsResponseData;
      setPaginationInfo({ page, totalPages, limit });
    }
    setSearching(false);
  };

  const fetchNormalResults = async (finalParams) => {
    try {
      const data = await patientDashboardPublicApi.getResults(finalParams);
      return data;
    } catch (error) {
      console.log('error', error);
    }
  };

  const getRiskModelsSummary = (data) => {
    if (!data.riskAssessment) {
      return;
    }
    setriskModelsSummary(data.riskAssessment);

    let riskModelsValue = false;
    Object.keys(data.riskAssessment).forEach((key) => {
      if (!!data.riskAssessment[key]?.isSuccess) riskModelsValue = true;
    });
    setHasRiskModelsValue(riskModelsValue);
  };

  const getRiskAssessmentHistory =  (data) => {
    if (!data.riskAssessmentHistory || data.riskAssessmentHistory.length === 0) {
      return;
    }
    setRiskAssessmentHistory(data.riskAssessmentHistory);
  };

  const getRiskAssHistoryForModel = (modelLable = '', riskAssessmentHistory) => {
    if (!riskAssessmentHistory || riskAssessmentHistory?.lenght < 1) return [];
    const history = [];
    const modelWords = modelLable?.split(' ')?.join('')?.toLowerCase() || '';
    riskAssessmentHistory.forEach((rah) => {
      const rahModelKeys = [
        'cardiovascular',
        'coronaryHeartDisease',
        'diabetes',
        'hypertension',
        'liver',
        'kidney_stage3',
        'kidney_stage5',
        'goutDisease',
      ];
      rahModelKeys.forEach((modelKey) => {
        if (
          modelWords.includes(modelKey.toLowerCase()) &&
          rah?.riskAssessment?.[modelKey]
        ) {
          history.push(rah.riskAssessment[modelKey]);
        }
      });
    });
    return history.reverse();
  };

  const fetchMedicalProfile = (profile) => {
    try {
      setMedProfile(profile);
      patHealthDetails(profile);
    } catch (error) {
      console.log('error', error);
    }
  };

  const fetchPatient = async () => {
    try {
      setFetchingPatient(true);
      const data = await fetchNormalPatient();
      if (!data) return;
      setPatientPhoto(data?.profilePic);
      setPatientDetails(data);
      let profile = data?.profile;
      if (data?.profiles)
        profile = data.profiles.find((prof) => prof.userId === data.id);
      setMedProfile(profile);
      fetchMedicalProfile(profile);
      const riskAssessment =
        data?.riskAssessment || data.riskAssessments[data.id];
      const riskAssessmentHistory = data?.riskAssessmentHistory || data.riskAssessmentHistories[data.id];
      data.riskAssessment = riskAssessment;
      data.riskAssessmentHistory = riskAssessmentHistory;
      getRiskModelsSummary(data);
      getRiskAssessmentHistory(data);

      if (data?.sameEmailPatientDetails) {
        const result = await IdClientService.getClientsByToken();
        const detailsArray = [];
        result.data?.data.forEach((client) => {
          const details = data.sameEmailPatientDetails.find(
            (itm) => itm.clientId === client.clientId
          );

          detailsArray.push({
            id: data.id,
            ...details,
            ...client,
          });
        });

        const filteredArr = [];
        detailsArray.forEach((dets) => {
          const profile = data.profiles.find(
            (prof) => prof?.userId === dets?.id
          );

          if (
            profile?.bmiList?.length ||
            profile?.bloodPressureList?.length ||
            profile?.pulseOxList?.length
          )
            filteredArr.push(dets);
        });
        const uniqueArr = Array.from(
          new Map(filteredArr.map((item) => [item.id, item])).values()
        );
        fetchMedicalProfile(
          data.profiles.find((prof) => prof?.userId === uniqueArr[0]?.id)
        );
        setActiveTab(uniqueArr[0]?.clientId);
        setPatClientGroupDetails(uniqueArr);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setFetchingPatient(false);
    }
  };

  const fetchNormalPatient = async () => {
    const { data } = await patientDashboardPublicApi.getCurrentPatient();
    if (!!data) return data;
    return null;
  };

  const patHealthDetails = (patient) => {
    const {
      height,
      weight,
      heartRate,
      systolicUpperNumber,
      diastolicLowerNumber,
      pulseOximeter,
      perfusionIndex,
    } = medicalProfileProviderApi.extractLatestHealthDetails(patient);
    const patientHealthDetails = [
      {
        title: 'Height',
        field: 'height',
        value: height,
        unit: 'cm',
        hasValue: !!height,
      },
      {
        title: 'Weight',
        field: 'weight',
        value: weight,
        unit: 'kg',
        hasValue: !!weight,
      },
      {
        title: 'Systolic BP',
        field: 'systolicUpperNumber',
        value: systolicUpperNumber,
        unit: 'mm Hg',
        hasValue: !!systolicUpperNumber,
      },
      {
        title: 'Diastolic BP',
        field: 'diastolicLowerNumber',
        value: diastolicLowerNumber,
        unit: 'mmHg',
        hasValue: !!diastolicLowerNumber,
      },
      {
        title: 'Heart Rate',
        field: 'heartRate',
        value: heartRate,
        unit: 'bpm',
        hasValue: !!heartRate,
      },
      {
        title: 'Pulse OX (SpO2)',
        field: 'pulseOximeter',
        value: pulseOximeter,
        unit: '%',
        hasValue: !!pulseOximeter,
      },
      {
        title: 'Perfusion Index',
        field: 'perfusionIndex',
        value: perfusionIndex,
        unit: '%',
        hasValue: !!perfusionIndex,
      },
      // {
      //   title: 'Respiratory Rate',
      //   field: 'respiratoryRate',
      //   value: patient.resp,
      //   unit: 'bpm',
      //   hasValue: !!patient.resp,
      // },
    ];
    setHealthDetails(patientHealthDetails);
  };

  const onRowClick = (e, report) => {
    e.stopPropagation();
    e.preventDefault();

    if (e.metaKey || e.ctrlKey) {
      if (report.version === 'fhir') {
        return window.open(`/results/fhir/${report.fhirId}`, '_blank');
      }

      return window.open(`/results/${report.id}`, '_blank');
    }

    if (report.version === 'fhir') {
      return history.push({
        pathname: `/results/fhir/${report.fhirId}`,
        state: { diagnosticReport: report },
      });
    }

    return history.push({
      pathname: `/results/${report.id}`,
      state: { diagnosticReport: report },
    });
  };

  const onHDetailChange = (field, value, callback) => {
    const details = { ...editedHealthDetails, [field]: value };
    setEditedHealthDetails(details);
    callback(value);
  };

  const getIcon = (name) => {
    if (name === 'cvd') return cardioSvg;
    if (name === 'chd') return chdSvg;
    if (name === 'diabetes') return diabetesSvg;
    if (name === 'hypertension') return hypertensionSvg;
    if (name === 'liver') return naflSvg;
    if (name === 'cardiovascular') return cardioSvg;
    if (name === 'kidneyStage3') return kidneySvg;
    if (name === 'kidneyStage5') return kidneySvg;
  };

  const getIDLabel = (type) => {
    if (type === 'PPN') return 'Passport No.';
    if (type === 'NID') return 'National ID';
    if (type === 'SSN') return 'Social Security No.';
    if (type === 'SLN') return 'Drivers License No.';
    if (type === 'IRN') return 'Insurance Reference No.';
    if (type === 'CID') return 'Company ID';
    return 'NRIC/FIN';
  };
  console.log(
    '55 !fetchingPatient && !patientDetails',
    fetchingPatient,
    patientDetails,
    !fetchingPatient && !patientDetails
  );

  const onClientClick = async (details) => {
    setSelectedTab(details);
    setActiveTab(details?.clientId);
    const profile = patientDetails.profiles.find(
      (prof) => prof.userId === details.id
    );
    setMedProfile(profile);
    setMedProfile(profile);
    fetchMedicalProfile(profile);
    const riskAssessment = patientDetails.riskAssessments[details.id];
    const data = { riskAssessment };
    getRiskModelsSummary(data);
  };

  return (
    <div className="patient-details i-pat-dash">
      {!fetchingPatient && !patientDetails ? (
        <div className="patient-details__placeholder">
          <Text bold center>
            Patient not found
          </Text>
        </div>
      ) : (
        <>
          <div className="patient-details__section">
            <div className="patient-details__details-wrapper">
              {fetchingPatient ? (
                <div className="patient-details__details-wrapper__loader-wrapper">
                  <Loader
                    active
                    className="patient-details__details-wrapper__loader-wrapper__loader"
                    size="small"
                  />
                </div>
              ) : (
                <>
                  <div className="patient-details__patient-photo-wrapper">
                    <div className="patient-details__patient-photo-wrapper__pic-name">
                      <div className="patient-details__patient-photo">
                        {(patientPhoto || medProfile?.profileUrl) && (
                          <img
                            src={patientPhoto || medProfile?.profileUrl}
                            alt="patient"
                          />
                        )}
                      </div>
                      <div className="patient-details__detail">
                        <Text size="big" dark className="ml-1 mb-1" uppercase>
                          {patientDetails.fullName}
                        </Text>
                        {patientDetails.id && (
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <img src={tinyBlueSvg} alt="logo" />
                            <Text color="blue" size="tinniest" className="mr-2">
                              Riverr ID
                            </Text>
                            <Text color="blue" size="tiny" dark capitalize>
                              {patientDetails.id.substring(0, 3)}-
                              {patientDetails.id.substring(3, 6)}
                            </Text>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="patient-details__details-wrapper__row patient-details__details-wrapper__row--line">
                    {patientDetails.dateOfBirth && (
                      <div>
                        <Text type="dataLabel" id="patient_overview.birth_date">
                          Date of Birth
                        </Text>
                        <Text type="data">
                          {moment(patientDetails.dateOfBirth, [
                            'DD-MM-YYYY',
                            'YYYY-MM-DD',
                          ]).format('DD-MM-YYYY')}{' '}
                          (
                          {moment().diff(
                            moment(patientDetails.dateOfBirth, [
                              'DD-MM-YYYY',
                              'YYYY-MM-DD',
                            ]),
                            'years'
                          )}{' '}
                          years old)
                        </Text>
                      </div>
                    )}

                    {patientDetails.gender && (
                      <div>
                        <Text type="dataLabel" id="patient_overview.gender">
                          Gender
                        </Text>
                        <Text type="data" uppercase>
                          {patientDetails.gender}
                        </Text>
                      </div>
                    )}
                    {patientDetails.nationality && (
                      <div>
                        <Text
                          type="dataLabel"
                          id="patient_overview.nationality_"
                        >
                          Nationality
                        </Text>
                        <Text type="data" uppercase>
                          {patientDetails.nationality}
                        </Text>
                      </div>
                    )}

                    {(patientDetails?.idNo || patientDetails?.ssn) && (
                      <div>
                        <Text type="dataLabel">
                          {getIDLabel(patientDetails?.idType)}
                        </Text>
                        <Text type="data">
                          {patientDetails?.idNo || patientDetails?.ssn}
                        </Text>
                      </div>
                    )}

                    {(patientDetails.passNo ||
                      patientDetails?.identified?.passportNumber) && (
                      <div>
                        <Text
                          type="dataLabel"
                          id="patient_overview.passport_number"
                        >
                          Passport Number
                        </Text>
                        <Text type="data">
                          {patientDetails.passNo ||
                            patientDetails?.identified?.passportNumber}
                        </Text>
                      </div>
                    )}
                    {patientDetails.email && (
                      <div>
                        <Text type="dataLabel" id="patient_overview.email">
                          Email
                        </Text>
                        <Text type="data">{patientDetails.email}</Text>
                      </div>
                    )}

                    {patientDetails.phoneNumber && (
                      <div>
                        <Text
                          type="dataLabel"
                          id="patient_overview.phone_number"
                        >
                          Phone Number
                        </Text>
                        <Text type="data">{patientDetails.phoneNumber}</Text>
                      </div>
                    )}
                    {patientDetails.employer && (
                      <div>
                        <Text type="dataLabel">Employer</Text>
                        <Text type="data">{patientDetails?.employer}</Text>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          {patientDetails?.sameEmailPatientDetails &&
          patClientGroupDetails?.length ? (
            <CustomMenu
              menuItems={patClientGroupDetails}
              onItemClick={onClientClick}
            />
          ) : (
            ''
          )}
          {!fetchingPatient && (
            <div className="patient-details__section">
              <Text
                bold
                size="big"
                className="mb-3"
                id="patient_overview.personal_health_details"
              >
                Personal Health Details
              </Text>
              <div className="patient-details__details-wrapper">
                <div className="patient-details__vitals">
                  {healthDetails.map((data) => (
                    <Controller
                      name={data.field}
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { onChange, value } }) => (
                        <HealthDetail
                          title={data.title}
                          field={data.field}
                          value={data.value}
                          unit={data.unit}
                          isEdit={isEditHealthDetails}
                          onChange={({ field, value }) =>
                            onHDetailChange(field, value, onChange)
                          }
                          errors={errors}
                        />
                      )}
                    />
                  ))}
                </div>
                {(medProfile?.bmiList ||
                  medProfile?.bloodPressureList ||
                  medProfile?.pulseOxList) && (
                  <div className="patient-details__vitals-graphs">
                    {medProfile?.bmiList && medProfile?.bmiList[0] && (
                      <div className="patient-details__vital-graph">
                        {/* <Text dark size="big">
                  BMI
                </Text> */}
                        <BmiVisual
                          isClient
                          index={0}
                          row={{
                            height: latestBmi?.height.value,
                            heightUnit: latestBmi?.height.unit,
                            weight: latestBmi?.weight.value,
                            weightUnit: latestBmi?.weight.unit,
                            bmi: latestBmi?.value,
                            status: latestBmi?.status,
                            date: latestBmi?.updatedAt,
                          }}
                        />
                      </div>
                    )}
                    {medProfile?.bloodPressureList &&
                      medProfile?.bloodPressureList[0] && (
                        <div className="patient-details__vital-graph">
                          {/* <Text dark size="big">
                    Blood Pressure
                  </Text> */}
                          <BpVisual
                            isClient
                            index={0}
                            row={{
                              systolic:
                                latestBloodPressure?.systolicUpperNumber,
                              diastolic:
                                latestBloodPressure?.diastolicLowerNumber,
                              pulse: latestBloodPressure?.heartRate,
                              status: latestBloodPressure?.status,
                              date: latestBloodPressure?.updatedAt,
                            }}
                          />
                        </div>
                      )}
                    {medProfile?.pulseOxList && medProfile?.pulseOxList[0] && (
                      <div className="patient-details__vital-graph">
                        {/* <Text dark size="big">
                  Pulse Ox
                </Text> */}
                        <OxyVisual
                          isClient
                          index={0}
                          row={{
                            o2: latestPulseOx?.pulseOximeter,
                            pi: latestPulseOx?.perfusionIndex,
                            pulse: latestPulseOx?.heartRate,
                            risk: latestPulseOx?.risk,
                            date: latestPulseOx?.updatedAt,
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          {!!hasRiskModelsValue &&
            Boolean(Object.keys(riskModelsSummary)?.length) && (
              <div className="patient-details__section">
                <Text
                  bold
                  size="big"
                  id="react_single_overview.risk_assessment"
                >
                  Risk Assessment
                </Text>
                <div className="patient-details__risk-ass-wrapper">
                  {Object.keys(riskModelsSummary).map((key) => (
                    <>
                      {riskModelsSummary[key]?.data?.score && (
                        <div className="patient-details__risk-ass-wrapper__model">
                          <RiskAssessmentCompact
                            modelName={key}
                            clientView
                            data={riskModelsSummary[key]?.data}
                            icon={getIcon(key)}
                            getDataHistoryByModelName={getRiskAssHistoryForModel}
                            showHistoryToggle
                            riskAssessmentHistory={riskAssessmentHistory}
                          />
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
            )}
        </>
      )}
    </div>
  );
};

export default withRouter(PatientDashboard);
