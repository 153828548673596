import axios from './axiosInstance';

const methods = {
  async getDownloadUrl({ bucketName, fileName }) {
    const res = await axios.post(`/s3/download-url`, { bucketName, fileName });
    return res.data.downloadUrl;
  },
  async getUploadUrl({ bucketName, fileName, fileType }) {
    return axios.post(`/s3/upload-url`, { bucketName, fileName, fileType });
  },
};

export default methods;
