import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';

import { RichTextEditor, Text } from 'components';

import BioSec from './components/biomarkerSection/BiomarkerSection';
import SimpleBioSec from './components/simpleBiomarkerSection/SimpleBiomarkerSection';
import RiskAssessmentCompact from 'components/RiskAssessment/RiskAssessmentCompact/RiskAssessmentCompact';
import RiskAssessment from 'components/RiskAssessment/RiskAssessment';

import HeartHandIcon from 'assets/new/heartHand';
import ConnectedCircles from 'assets/new/connectedCircles';
import cardioSvg from 'assets/new/patientApp/cardiovascular.svg';
import chdSvg from 'assets/new/patientApp/chd.svg';
import diabetesSvg from 'assets/new/patientApp/diabetes.svg';
import hypertensionSvg from 'assets/new/patientApp/hypertension.svg';
import naflSvg from 'assets/new/patientApp/nafl.svg';
import infoBubbleSlantSvg from 'assets/new/infoBubbleSlant.svg';
import healthMonitorSvg from 'assets/new/healthMonitor.svg';
import doctorNoteSvg from 'assets/new/note.svg';
import kidneySvg from 'assets/new/patientApp/kidney.svg';

const bmiColors = {
  underweight: { color: 'grey', colorCode: '#9fabbd' },
  normal: { color: 'green', colorCode: '#00c48c' },
  overweight: { color: 'yellow', colorCode: '#ffd443' },
  obese: { color: 'orange', colorCode: '#ffaf3e' },
  'very obese': { color: 'red', colorCode: '#ff6666' },
};
const bpColors = {
  Normal: { color: 'green', colorCode: '#00c48c' },
  Elevated: { color: 'yellow', colorCode: '#ffd443' },
  'High Blood Pressure (for both hypertension 1 and 2)': {
    color: 'red',
    colorCode: '#ff6666',
  },
  'High Blood Pressure': {
    color: 'red',
    colorCode: '#ff6666',
  },
  'Normal-High Blood Pressure': {
    color: 'red',
    colorCode: '#ff6666',
  },
  'Hypertension Crisis': { color: 'red2 ', colorCode: '#ff3a3a' },
};
const oxyColors = {
  Normal: { color: 'green', colorCode: '#00c48c' },
  Borderline: { color: 'yellow', colorCode: '#ffd443' },
  'High Risk': { color: 'orange', colorCode: '#ffaf3e' },
  'Very High Risk': { color: 'red2', colorCode: '#ff3a3a' },
  'Low Heart Rate': { color: 'red', colorCode: '#ff6666' },
};

import './smartReport.scss';

const SmartReport = ({
  patient,
  observations,
  categoryDescriptions,
  graphData,
  doctorsNotes,
  riskModelsSummary,
  vitals,
  reactSessionReports,
}) => {
  const [data, setData] = useState([]);
  const [otherSessionReports, setOtherSessionReports] = useState([]);

  useEffect(() => {
    if (
      !observations ||
      !Object.keys(observations) ||
      !Object.keys(observations)?.length
    )
      return;
    const cats = Object.keys(observations);
    const othersCat = cats.splice(cats.indexOf('undefined'), 1)[0];
    const normalCats = cats.filter((cat) => !observations[cat]?.abnormalCount);
    const abnormalCats = cats.filter((cat) => observations[cat]?.abnormalCount);

    normalCats?.sort((key1, key2) =>
      String(categoryDescriptions[key1]?.category).localeCompare(
        String(categoryDescriptions[key2]?.category)
      )
    );
    abnormalCats?.sort((key1, key2) =>
      String(categoryDescriptions[key1]?.category).localeCompare(
        String(categoryDescriptions[key2]?.category)
      )
    );

    setData([...abnormalCats, ...normalCats, othersCat]);
  }, []);

  const getIcon = (name) => {
    if (name === 'cvd') return cardioSvg;
    if (name === 'chd') return chdSvg;
    if (name === 'diabetes') return diabetesSvg;
    if (name === 'hypertension') return hypertensionSvg;
    if (name === 'liver') return naflSvg;
    if (name === 'cardiovascular') return cardioSvg;
    if (name === 'coronaryHeartDisease') return chdSvg;
    if (name === 'kidney_stage3') return kidneySvg;
    if (name === 'kidney_stage5') return kidneySvg;
    if (name === 'goutDisease') return kidneySvg;
  };

  useEffect(() => {
    if (!reactSessionReports) return;
    const otherSessionReports = reactSessionReports?.filter(
      (report) => report?.version !== 'fhir'
    );
    setOtherSessionReports(otherSessionReports);
  }, [reactSessionReports]);

  const renderCat = useCallback(
    (key) => {
      return (
        <div className="smart-rep__section" key={key}>
          <div className="smart-rep__cat__header">
            {key !== 'undefined'
              ? categoryDescriptions[key]?.category
              : 'Other'}
          </div>
          <div className="smart-rep__cat__text">
            {categoryDescriptions?.[key]?.description || ''}
          </div>

          {observations[key]?.High?.range && (
            <>
              {observations[key]?.High?.range.map((obs) => (
                <BioSec observation={obs} graphData={graphData} />
              ))}
            </>
          )}
          {observations[key]?.High?.value && (
            <>
              {observations[key]?.High?.value.map((obs) => (
                <BioSec observation={obs} graphData={graphData} />
              ))}
            </>
          )}
          {observations[key]?.Low?.range && (
            <>
              {observations[key]?.Low?.range.map((obs) => (
                <BioSec observation={obs} graphData={graphData} />
              ))}
            </>
          )}
          {observations[key]?.Low?.value && (
            <>
              {observations[key]?.Low?.value.map((obs) => (
                <BioSec observation={obs} graphData={graphData} />
              ))}
            </>
          )}
          {observations[key]?.Normal?.range && (
            <>
              {observations[key]?.Normal?.range
                .sort((a, b) => {
                  return a.status_description ? -1 : 1;
                })
                .map((obs) => (
                  <BioSec observation={obs} graphData={graphData} />
                ))}
            </>
          )}
          <div className="smart-rep__cat__values-sec">
            {observations[key]?.Normal?.value && (
              <>
                {observations[key]?.Normal?.value.map((obs) => (
                  <SimpleBioSec observation={obs} />
                ))}
              </>
            )}
            {observations[key]?.Low?.value && (
              <>
                {observations[key]?.Low?.value.map((obs) => (
                  <SimpleBioSec observation={obs} />
                ))}
              </>
            )}
            {observations[key]?.undefined?.value && (
              <>
                {observations[key]?.undefined?.value.map((obs) => (
                  <SimpleBioSec observation={obs} />
                ))}
              </>
            )}
          </div>
        </div>
      );
    },
    [observations, graphData, categoryDescriptions]
  );
  console.log('44 observations', observations);
  console.log('44 categoryDescriptions', categoryDescriptions);
  return (
    <div className="smart-rep">
      <div className="smart-rep__section">
        <div className="smart-rep__pat__name">{patient.fullName}</div>
        <div className="smart-rep__pat__details">
          <div className="smart-rep__pat__details__det">
            <span className="smart-rep__pat__details__det__label">DOB</span>{' '}
            {moment(patient.dateOfBirth, 'YYYY-MM-DD').format('DD-MMM-YYYY')}{' '}
            (Age {moment().diff(patient.dateOfBirth, 'years')})
          </div>
          <div className="smart-rep__pat__details__det">
            <span className="smart-rep__pat__details__det__label">
              NRIC/FIN
            </span>{' '}
            {patient.ssn}
          </div>
          <div className="smart-rep__pat__details__det">
            <span className="smart-rep__pat__details__det__label">Gender</span>{' '}
            {patient.gender}
          </div>
          <div className="smart-rep__pat__details__det">
            <span className="smart-rep__pat__details__det__label">
              Passport No.
            </span>{' '}
            {patient.passNo}
          </div>
        </div>
      </div>
      {(vitals?.bmi || vitals?.bloodPressure || vitals?.pulseOx) && (
        <div className="smart-rep__vitals__section">
          <div className="smart-rep__vitals">
            <div className="smart-rep__header">
              <img
                src={healthMonitorSvg}
                color="black"
                className="lr-dn__icon"
              />
              Vitals
            </div>
            <div className="smart-rep__vitals__wrapper">
              {vitals?.bmi && (
                <div className="smart-rep__vitals__item">
                  <div className="smart-rep__vitals__item__details">
                    Body Mass Index
                    <span className="smart-rep__vitals__item__details__value">
                      {vitals?.bmi?.value}&nbsp;
                      <span>
                        kg/m<sup>2</sup>
                      </span>
                    </span>
                    <span
                      className="smart-rep__vitals__item__details__status"
                      style={{
                        color: bmiColors[vitals?.bmi?.status].colorCode,
                        borderColor: bmiColors[vitals?.bmi?.status]?.colorCode,
                      }}
                    >
                      {vitals?.bmi?.status}
                    </span>
                  </div>
                  <div className="smart-rep__vitals__item__info">
                    <span className="smart-rep__vitals__item__info__date">
                      {vitals?.bmi?.updatedAt
                        ? moment(vitals?.bmi?.updatedAt).format('LL HH:mm')
                        : vitals?.bmi?.date
                        ? moment(vitals?.bmi?.date).format('LL HH:mm')
                        : ''}
                    </span>
                  </div>
                </div>
              )}
              {vitals?.bloodPressure && (
                <div className="smart-rep__vitals__item">
                  <div className="smart-rep__vitals__item__details">
                    Blood Pressure
                    <span className="smart-rep__vitals__item__details__multi-value-wrapper">
                      <span className="smart-rep__vitals__item__details__multi-value">
                        <span style={{ display: 'flex' }}>
                          <span className="smart-rep__vitals__item__details__value-multi">
                            {vitals?.bloodPressure?.systolicUpperNumber}
                          </span>
                          <span className="smart-rep__vitals__item__details__value__type-multi">
                            Sys
                          </span>
                        </span>
                        <span style={{ display: 'flex' }}>
                          <span className="smart-rep__vitals__item__details__value-multi">
                            {vitals?.bloodPressure?.diastolicLowerNumber}
                          </span>
                          <span className="smart-rep__vitals__item__details__value__type-multi">
                            Dia
                          </span>
                        </span>
                      </span>
                      <span>mm Hg</span>
                    </span>
                    <span
                      className="smart-rep__vitals__item__details__status"
                      style={{
                        color:
                          bpColors[vitals?.bloodPressure?.status]?.colorCode,
                        borderColor:
                          bmiColors[vitals?.bloodPressure?.status]?.colorCode,
                      }}
                    >
                      {vitals?.bloodPressure?.status}
                    </span>
                  </div>
                  <div className="smart-rep__vitals__item__info">
                    <span className="smart-rep__vitals__item__info__date">
                      {vitals?.bloodPressure?.updatedAt
                        ? moment(vitals?.bloodPressure?.updatedAt).format(
                            'LL HH:mm'
                          )
                        : ''}
                    </span>
                  </div>
                </div>
              )}
              {vitals?.pulseOx && (
                <div className="smart-rep__vitals__item">
                  <div className="smart-rep__vitals__item__details">
                    Pulse Ox
                    <span className="smart-rep__vitals__item__details__value">
                      {vitals?.pulseOx?.pulseOximeter}%
                    </span>
                    <span
                      className="smart-rep__vitals__item__details__status"
                      style={{
                        color: oxyColors[vitals?.pulseOx?.risk]?.colorCode,
                        borderColor:
                          oxyColors[vitals?.pulseOx?.risk]?.colorCode,
                      }}
                    >
                      {vitals?.pulseOx?.risk}
                    </span>
                  </div>
                  <div className="smart-rep__vitals__item__info">
                    <span className="smart-rep__vitals__item__info__date">
                      {vitals?.pulseOx?.updatedAt
                        ? moment(vitals?.bloodPressure?.updatedAt).format(
                            'LL HH:mm'
                          )
                        : ''}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {vitals?.visualAcuity && (
            <div
              className="smart-rep__vitals__section"
              style={{ marginTop: '10px' }}
            >
              <div className="smart-rep__vitals">
                <div className="smart-rep__header">
                  <img
                    src={healthMonitorSvg}
                    color="black"
                    className="lr-dn__icon"
                  />
                  Visual Acuity
                </div>
                <div className="smart-rep__vitals__wrapper">
                  {vitals?.visualAcuity?.hasSnellenTest && (
                    <div className="smart-rep__vitals__item--va">
                      {vitals?.visualAcuity?.snellenTestVAid && (
                        <div className="smart-rep__vitals__item__details--va">
                          Visual aid
                          <span className="smart-rep__vitals__item__details__value">
                            {vitals?.visualAcuity?.snellenTestVAid}
                          </span>
                        </div>
                      )}
                      {vitals?.visualAcuity?.snellenTestOd && (
                        <div className="smart-rep__vitals__item__details--va">
                          OD (right)
                          <span className="smart-rep__vitals__item__details__value">
                            {vitals?.visualAcuity?.snellenTestOd}
                          </span>
                          <span
                            className="smart-rep__vitals__item__details__status--va"
                            style={{
                              color:
                                vitals?.visualAcuity?.snellenTestOdCategory
                                  ?.colorCode,
                              borderColor:
                                vitals?.visualAcuity?.snellenTestOdCategory
                                  ?.colorCode,
                            }}
                          >
                            {vitals?.visualAcuity?.snellenTestOdCategory?.text}
                          </span>
                        </div>
                      )}
                      {vitals?.visualAcuity?.snellenTestOs && (
                        <div className="smart-rep__vitals__item__details--va">
                          OS (left)
                          <span className="smart-rep__vitals__item__details__value">
                            {vitals?.visualAcuity?.snellenTestOs}
                          </span>
                          <span
                            className="smart-rep__vitals__item__details__status--va"
                            style={{
                              color:
                                vitals?.visualAcuity?.snellenTestOsCategory
                                  ?.colorCode,
                              borderColor:
                                vitals?.visualAcuity?.snellenTestOsCategory
                                  ?.text,
                            }}
                          >
                            {vitals?.visualAcuity?.snellenTestOsCategory?.text}
                          </span>
                        </div>
                      )}
                      {vitals?.visualAcuity?.snellenTestUpdatedAt && (
                        <div className="smart-rep__vitals__item__info">
                          <span className="smart-rep__vitals__item__info__date">
                            {vitals?.visualAcuity?.snellenTestUpdatedAt &&
                              moment(
                                vitals?.visualAcuity?.snellenTestUpdatedAt
                              ).format('LL HH:mm')}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                  {vitals?.visualAcuity?.hasVisualRefractionTest && (
                    <div className="smart-rep__vitals__item--va">
                      {vitals?.visualAcuity?.visualRefractionTestOd && (
                        <div className="smart-rep__vitals__item__details--va">
                          OD (right)
                          <span className="smart-rep__vitals__item__details__value">
                            {vitals?.visualAcuity?.visualRefractionTestOd}
                          </span>
                        </div>
                      )}
                      {vitals?.visualAcuity?.visualRefractionTestOs && (
                        <div className="smart-rep__vitals__item__details--va">
                          OS (left)
                          <span className="smart-rep__vitals__item__details__value">
                            {vitals?.visualAcuity?.visualRefractionTestOs}
                          </span>
                        </div>
                      )}
                      {vitals?.visualAcuity?.visualRefractionTestUpdatedAt && (
                        <div className="smart-rep__vitals__item__info">
                          <span className="smart-rep__vitals__item__info__date">
                            {vitals?.visualAcuity
                              ?.visualRefractionTestUpdatedAt &&
                              moment(
                                vitals?.visualAcuity
                                  ?.visualRefractionTestUpdatedAt
                              ).format('LL HH:mm')}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                  {vitals?.visualAcuity?.hasColorBlindness && (
                    <div className="smart-rep__vitals__item--va">
                      {vitals?.visualAcuity?.colorBlindness && (
                        <div className="smart-rep__vitals__item__details--va">
                          Color Blindness
                          <span className="smart-rep__vitals__item__details__value">
                            {vitals?.visualAcuity?.colorBlindness}
                          </span>
                        </div>
                      )}
                      {vitals?.visualAcuity?.colorBlindnessUpdatedAt && (
                        <div className="smart-rep__vitals__item__info">
                          <span className="smart-rep__vitals__item__info__date">
                            {vitals?.visualAcuity?.colorBlindnessUpdatedAt &&
                              moment(
                                vitals?.visualAcuity?.colorBlindnessUpdatedAt
                              ).format('LL HH:mm')}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                  {vitals?.visualAcuity?.hasEyePressure && (
                    <div className="smart-rep__vitals__item--va">
                      {vitals?.visualAcuity?.eyePressure && (
                        <div className="smart-rep__vitals__item__details--va">
                          Eye Pressure
                          <span className="smart-rep__vitals__item__details__value">
                            {vitals?.visualAcuity?.eyePressure}mmHg
                          </span>
                        </div>
                      )}
                      {vitals?.visualAcuity?.eyePressureUpdatedAt && (
                        <div className="smart-rep__vitals__item__info">
                          <span className="smart-rep__vitals__item__info__date">
                            {vitals?.visualAcuity?.eyePressureUpdatedAt &&
                              moment(
                                vitals?.visualAcuity?.eyePressureUpdatedAt
                              ).format('LL HH:mm')}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {doctorsNotes && (
        <div
          className="smart-rep__section"
          style={{ borderTop: 'none', paddingBottom: '0' }}
        >
          <div className="smart-rep__notes">
            <div className="smart-rep__header">
              <HeartHandIcon color="black" className="lr-dn__icon" />
              Doctor's Notes
            </div>
            {/* <div className="smart-rep__pat__details">
              <div className="smart-rep__header">Doctor-in-charge</div>
              <div className="smart-rep__pat__details__det">
                <span className="smart-rep__pat__details__det__label">Doctors Name</span>{' '}
                {moment(patient.dateOfBirth, 'YYYY-MM-DD').format('DD-MMM-YYYY')}
              </div>
              <div className="smart-rep__pat__details__det">
                <span className="smart-rep__pat__details__det__label">
                  MCR No
                </span>{' '}
                {patient.ssn}
              </div>
            </div> */}
            <div className="smart-rep__notes__note-wrapper">
              <RichTextEditor
                className="smart-rep__notes__note"
                editorState={EditorState.createWithContent(
                  convertFromRaw(JSON.parse(doctorsNotes))
                )}
                viewer={true}
                readOnly={true}
              />
            </div>
          </div>
        </div>
      )}
      {otherSessionReports && otherSessionReports?.length > 0 && (
        <div
          className="smart-rep__section"
          style={{ borderTop: 'none', paddingBottom: '0' }}
        >
          <div className="smart-rep__notes">
            <div className="smart-rep__header">
              <img src={doctorNoteSvg} alt="notes" className="lr-dn__icon" />
              Other Diagnostic Report(s)
            </div>
            {otherSessionReports?.map((report) => (
              <div className="smart-rep__notes__other-note-wrapper">
                <div className="smart-rep__notes__note-wrapper__header">
                  {report?.pdf?.at(0)?.type || 'N.A (Unspecified)'}
                </div>
                <div className="smart-rep__notes__note-wrapper__abnormal">
                  <span className="smart-rep__notes__note-wrapper__abnormal__label">
                    Abnormalities observed:
                  </span>
                  <span
                    className={`smart-rep__notes__note-wrapper__abnormal__value--${
                      report.anomalyStatus === 'abnormal' ? 'red' : 'black'
                    }`}
                  >
                    {report.anomalyStatus === 'abnormal' ? 'Yes' : 'None'}
                  </span>
                </div>
                {Boolean(report?.doctorNote) && (
                  <span className="smart-rep__notes__note">
                    <strong>Notes: </strong> {report?.doctorNote}
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {riskModelsSummary && Boolean(Object.keys(riskModelsSummary).length) && (
        <div className="smart-rep__section" style={{ marginTop: '1.5rem' }}>
          <div className="smart-rep__risk-summary">
            <div className="smart-rep__header">
              <ConnectedCircles color="black" className="lr-dn__icon" />
              Risk Assessment
            </div>
            <div className="smart-rep__sub-header">
              Your Calculated Risk Overview
            </div>
            <div className="smart-rep__sub-text">
              {/* 10-Year risk forecast based on (xx-xx) age group */}
              Risk forecast based on your age group
            </div>
            <div className="smart-rep__risk-summary__wrapper">
              {Object.keys(riskModelsSummary).map((key) => (
                <>
                  {Number.isFinite(riskModelsSummary[key]?.data?.score) ? (
                    <div className="smart-rep__risk-summary__wrapper__model">
                      <RiskAssessmentCompact
                        modelName={key}
                        data={riskModelsSummary[key]?.data}
                        icon={getIcon(key)}
                        small
                        hideToggle
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </>
              ))}
            </div>
          </div>
          <div>
            See more details in <strong>Appendix A</strong>
          </div>
        </div>
      )}
      {data.map((key) => (
        <>{renderCat(key)}</>
      ))}
      {riskModelsSummary && Boolean(Object.keys(riskModelsSummary).length) && (
        <div className="smart-rep__appendix-wrapper">
          <div className="smart-rep__appendix__title-header">Appendix</div>
          <div className="smart-rep__appendix__header">Appendix A</div>
          <div className="smart-rep__sub-header">
            <ConnectedCircles color="black" className="lr-dn__icon" />
            Risk Assessments In Detail
          </div>
          <div className="smart-rep__risk-details__wrapper">
            {Object.keys(riskModelsSummary).map((key) => (
              <>
                {riskModelsSummary[key]?.data?.score && (
                  <div className="smart-rep__risk-details__wrapper__model">
                    <RiskAssessment
                      modelName={key}
                      data={riskModelsSummary[key]?.data}
                      icon={getIcon(key)}
                      isPreview
                      // clientView
                    />
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      )}
      <div className="footer__riverr__text">
        <Text bold size="big" className="mr-2">
          ReACT Report
        </Text>
        <Text size="tinniest" color="lightGrey">
          powered by Riverr
        </Text>
      </div>
    </div>
  );
};

export default SmartReport;
