import { uniqBy } from 'lodash';
import moment from 'moment';

import NormalIcon from 'assets/range-icons/normal.png';
import HighIcon from 'assets/range-icons/high.png';
import LowIcon from 'assets/range-icons/low.png';
import DetectedIcon from 'assets/range-icons/detected.png';
import NotDetectedIcon from 'assets/range-icons/not-detected.png';

const statusValues = {
  borderline: {
    name: 'Borderline',
    color: 'orange',
    colorCode: '#ffaf3e',
    icon: HighIcon,
  },
  desirable: {
    name: 'Desirable',
    color: 'green',
    colorCode: '#00c48c',
    icon: NormalIcon,
  },
  high: { name: 'High', color: 'red', colorCode: '#ff6666', icon: HighIcon },
  microalbuminuria: {
    name: 'Microalbuminuria',
    color: 'red',
    colorCode: '#ff6666',
    icon: HighIcon,
  },
  macroalbuminuria: {
    name: 'Macroalbuminuria',
    color: 'red2',
    colorCode: '#ff3a3a',
    icon: HighIcon,
  },
  criticalhigh: {
    name: 'Very High',
    color: 'red2',
    colorCode: '#ff3a3a',
    icon: HighIcon,
  },
  veryhigh: {
    name: 'Very High',
    color: 'red2',
    colorCode: '#ff3a3a',
    icon: HighIcon,
  },
  verylow: {
    name: 'Very Low',
    color: 'yellow',
    colorCode: '#ffd443',
    icon: LowIcon,
  },
  lowrisk: {
    name: 'Low Risk',
    color: 'greenish2',
    colorCode: '#12b001',
    icon: NormalIcon,
  },
  averagerisk: {
    name: 'Average Risk',
    color: 'green',
    colorCode: '#00c48c',
    icon: NormalIcon,
  },
  highrisk: {
    name: 'High Risk',
    color: 'red',
    colorCode: '#ff6666',
    icon: HighIcon,
  },
  low: { name: 'Low', color: 'yellow', colorCode: '#ffd443', icon: LowIcon },
  optimal: {
    name: 'Optimal',
    color: 'greenish',
    colorCode: '#12b001',
    icon: NormalIcon,
  },
  suboptimal: {
    name: 'subOptimal',
    color: 'yellow',
    colorCode: '#ffd443',
    icon: LowIcon,
  },
  normal: {
    name: 'Normal',
    color: 'green',
    colorCode: '#00c48c',
    icon: NormalIcon,
  },
  sufficient: {
    name: 'Sufficient',
    color: 'green',
    colorCode: '#00c48c',
    icon: NormalIcon,
  },
  ideal: {
    name: 'Ideal',
    color: 'greenish2',
    colorCode: '#12b001',
    icon: NormalIcon,
  },
  grayzoneta: {
    name: 'Grayzoneta',
    color: 'greenish2',
    colorCode: '#12b001',
    icon: NormalIcon,
  },
  acceptable: {
    name: 'Acceptable',
    color: 'green',
    colorCode: '#00c48c',
    icon: DetectedIcon,
  },
  unacceptable: {
    name: 'Unacceptable',
    color: 'red',
    colorCode: '#ff6666',
    icon: NotDetectedIcon,
  },
  negative: {
    name: 'Negative',
    color: 'green',
    colorCode: '#00c48c',
    icon: NotDetectedIcon,
  },
  positive: {
    name: 'Positive',
    color: 'red',
    colorCode: '#ff6666',
    icon: DetectedIcon,
  },
  abnormal: {
    name: 'Abnormal',
    color: 'red',
    colorCode: '#ff6666',
    icon: HighIcon,
  },
  'non-reactive': {
    name: 'Non-Reactive',
    color: 'green',
    colorCode: '#00c48c',
    icon: NotDetectedIcon,
  },
  reactive: {
    name: 'Reactive',
    color: 'red',
    colorCode: '#ff6666',
    icon: DetectedIcon,
  },
  nil: {
    name: 'Nil',
    color: 'green',
    colorCode: '#00c48c',
    icon: NotDetectedIcon,
  },
  trace: {
    name: 'Trace',
    color: 'orange',
    colorCode: '#ffaf3e',
    icon: DetectedIcon,
  },
  deficient: {
    name: 'Trace',
    color: 'red',
    colorCode: '#ff6666',
    icon: DetectedIcon,
  },
  insufficient: {
    name: 'Trace',
    color: 'orange',
    colorCode: '#ffaf3e',
    icon: DetectedIcon,
  },
};

// const normalValues = {
//   Desirable: true,
//   Optimal: true,
//   Normal: true,
//   'Non-Reactive': true,
//   Nil: true,
//   Negative: true,
// };

const abnormalValues = {
  'Borderline High': true,
  High: true,
  Low: true,
  Trace: true,
  Reactive: true,
  Positive: true,
};

// function extractRange(range = '', regexPatern) {
//   try {
//     if (!range || !regexPatern) {
//       return null;
//     }
//     const groups = range.match(regexPatern);
//     const rangeAsString =
//       groups?.length > 1 ? range.match(regexPatern)[1] : null;
//     return JSON.parse(rangeAsString);
//   } catch (error) {
//     console.log(error);
//     return null;
//   }
// }

const abnormalStatuses = [
  'Critical high',
  'VeryHigh',
  'High',
  'Borderline High',
  'Low',
  'Reactive',
  'Abnormal',
];
const sortOrder = [
  'Critical high',
  'VeryHigh',
  'High',
  'Borderline High',
  'Low',
  'Positive',
  'Reactive',
  'Abnormal',
  'Trace',
  'Desirable',
  'Normal',
  'Negative',
  'Non-Reactive',
  'Ideal',
  'Optimal',
  'Sufficient',
  'Nil',
  'undefined',
];
const ordering = {};

const sortByStatus = (unsortedList) => {
  const list = [...unsortedList];
  for (var i = 0; i < sortOrder.length; i++) ordering[sortOrder[i]] = i;

  list.sort((a, b) => a.display.localeCompare(b.display));

  list.sort((a, b) => {
    return (
      ordering[a?.anomalyResult?.status] - ordering[b?.anomalyResult?.status]
    );
  });
  return list;
};

function convertAnomalyResult(anomalyResult) {
  if (!anomalyResult) {
    return null;
  }
  const text = anomalyResult.clinic_range || anomalyResult.reference_range;
  const regex = /([a-zA-Z\s]+)=\[(\d+(?:\.\d+)?),\s*(\d+(?:\.\d+)?)\]/g;

  let match;
  const extractedValues = [];

  while ((match = regex.exec(text)) !== null) {
    extractedValues.push({
      status: match[1],
      values: [match[2], match[3]],
    });
  }
  return {
    status: anomalyResult.status,
    category: anomalyResult.category,
    categoryId: anomalyResult.category_id,
    extractedRanges: extractedValues,
    isAbnormal: !!abnormalValues[anomalyResult.status],
  };
}

function convertToNumber(value, defaultFixedDecimal = 2) {
  if (isNaN(value)) {
    console.log('Invalid number', value);
    return value;
  }

  const valueStr = value.toString();
  const decimalIndex = valueStr.indexOf('.');

  let fixedDecimal = defaultFixedDecimal;
  if (decimalIndex !== -1) {
    fixedDecimal = valueStr.length - decimalIndex - 1;
  }

  return Number(value).toFixed(Math.max(fixedDecimal, defaultFixedDecimal));
}


function convertToDisplayedObservation(
  rawObservation,
  graphDataByBiomarkerId = undefined
) {
  const observation = rawObservation.rawResource.jsonData;
  const anomalyResult = rawObservation.anomalyResult;

  const coding = observation.code?.coding?.find((coding) =>
    Boolean(coding.display)
  );

  const displayedObservation = {
    id: observation.id || '',
    display: coding?.display || '',
    code: coding?.code || '',
    effectiveDateTime: observation.effectiveDateTime || '',
    unit:
      (!!anomalyResult.unit &&
        (anomalyResult.unit === 'NA' ? null : anomalyResult.unit)) ||
      observation?.valueQuantity?.unit,
  };

  if (
    observation.valueQuantity?.value ||
    observation.valueQuantity?.value === 0
  ) {
    displayedObservation.value = convertToNumber(
      observation.valueQuantity.value
    );
  } else if (observation.valueCodeableConcept?.coding?.[0]?.display) {
    const val = observation.valueCodeableConcept?.coding?.[0]?.display;
    displayedObservation.value = !!val ? String(val).toUpperCase() : 'NA';
  }
  if (observation.valueString || observation.valueString === 0) {
    displayedObservation.value = observation.valueString;
  }
  if (graphDataByBiomarkerId) {
    const { code, unit } = displayedObservation;
    displayedObservation.dataPoints =
      graphDataByBiomarkerId[
        `${String(code).toUpperCase()}_${String(unit).toUpperCase()}`
      ];

    const extractedRanges =
      convertAnomalyResult(anomalyResult)?.extractedRanges;
    const obsRang = getObservationRange(extractedRanges);
    if (displayedObservation.dataPoints?.length > 1) {
      let filteredGraphPoints = displayedObservation.dataPoints.filter(
        (point) => point?.value !== ''
      );

      if (!filteredGraphPoints?.length) return;
      const uniqueFilteredGraphPoints = uniqBy(filteredGraphPoints, 'date');
      uniqueFilteredGraphPoints.sort(
        (a, b) => moment(a.date).unix() - moment(b.date).unix()
      );
      displayedObservation.dataPoints = uniqueFilteredGraphPoints;

      let latestDataPoint = Number(
        uniqueFilteredGraphPoints?.at(-1)?.value || 0
      )?.toFixed(2);
      displayedObservation.latestDataPointStatus = getValueRangeItem(
        latestDataPoint,
        'text',
        obsRang
      );
    }
  }

  if (anomalyResult) {
    displayedObservation.category = anomalyResult.category;
    displayedObservation.categoryId = anomalyResult.category_id;
    displayedObservation.anomalyResult = convertAnomalyResult(anomalyResult);
    displayedObservation.description = anomalyResult.biomarker?.description;
    displayedObservation.status_description =
      anomalyResult.biomarker?.status_description;
    displayedObservation.status = anomalyResult.biomarker?.status;
    displayedObservation.normalized_status =
      anomalyResult.biomarker?.normalized_status;
  }
  // TODO: check this out later
  return displayedObservation;
}

function getObservationRange(extractedRanges) {
  const obsExtractedRanges = extractedRanges;
  const obsRang = obsExtractedRanges?.map((range) => {
    const formattedStatus = range.status
      ?.replace('Range', '')
      ?.replace(' ', '')
      ?.toLowerCase();

    let subText;

    if (range?.values[0] === '0') subText = '<' + range?.values[1];
    else if (range?.values[1] === '99999') subText = '>' + range?.values[0];
    else subText = `${range.values[0]} - ${range.values[1]}`;

    return {
      text: range?.status,
      range: range?.values,
      subText,
      colorCode: statusValues[formattedStatus]?.colorCode,
      color: statusValues[formattedStatus]?.color,
      icon: statusValues[formattedStatus]?.icon,
    };
  });
  return obsRang;
}

function convertListOfObservations(observations = [], anomalyResultsMap = {}) {
  if (!observations.length) {
    return [];
  }

  const obs = observations.filter(Boolean).map((observation) => {
    const anomalyResult = anomalyResultsMap
      ? anomalyResultsMap[observation.id]
      : null;
    return convertToDisplayedObservation(observation, anomalyResult);
  });

  sortByStatus(obs);
  return obs;
}

const observationsByCat = (observations) => {
  const byCat = {};
  observations.forEach((obs) => {
    const enrichedObs = obs;
    const value = enrichedObs?.value
      ? enrichedObs?.value.toLowerCase()
      : enrichedObs?.value;
    if (!byCat[enrichedObs.categoryId])
      byCat[enrichedObs.categoryId] = { normalCount: 0, abnormalCount: 0 };
    if (!byCat[enrichedObs.categoryId][enrichedObs?.anomalyResult?.status])
      byCat[enrichedObs.categoryId][enrichedObs?.anomalyResult?.status] = {};
    // yo alfred, check this out
    if (
      !enrichedObs?.anomalyResult?.extractedRanges?.length ||
      (!value && value !== 0)
    ) {
      if (
        !byCat[enrichedObs.categoryId][enrichedObs?.anomalyResult?.status]
          .value &&
        byCat[enrichedObs.categoryId][enrichedObs?.anomalyResult?.status]
          .value !== 0
      )
        byCat[enrichedObs.categoryId][
          enrichedObs?.anomalyResult?.status
        ].value = [];
      byCat[enrichedObs.categoryId][enrichedObs?.anomalyResult?.status].value =
        [
          ...byCat[enrichedObs.categoryId][enrichedObs?.anomalyResult?.status]
            .value,
          enrichedObs,
        ];
    } else {
      if (
        !byCat[enrichedObs.categoryId][enrichedObs?.anomalyResult?.status].range
      )
        byCat[enrichedObs.categoryId][
          enrichedObs?.anomalyResult?.status
        ].range = [];
      byCat[enrichedObs.categoryId][enrichedObs?.anomalyResult?.status].range =
        [
          ...byCat[enrichedObs.categoryId][enrichedObs?.anomalyResult?.status]
            .range,
          enrichedObs,
        ];
    }
    if (
      enrichedObs?.anomalyResult?.isAbnormal ||
      enrichedObs?.anomalyResult?.status?.toLowerCase() === 'abnormal'
    ) {
      byCat[enrichedObs.categoryId].abnormalCount =
        byCat[enrichedObs.categoryId].abnormalCount + 1;
    } else {
      byCat[enrichedObs.categoryId].normalCount =
        byCat[enrichedObs.categoryId].normalCount + 1;
    }
  });

  if (byCat.Others && byCat.undefined) {
    byCat.Others = { ...byCat.undefined, ...byCat.Others };
    delete byCat.undefined;
  }

  return byCat;
};

const mapObservationsByStatus = (observations) => {
  const byStatus = {};

  observations.forEach((obs) => {
    if (obs?.anomalyResult?.status && !byStatus[obs?.anomalyResult?.status])
      byStatus[obs?.anomalyResult?.status] = [];
    if (obs?.anomalyResult?.status)
      byStatus[obs?.anomalyResult?.status] = [
        ...byStatus[obs?.anomalyResult?.status],
        obs,
      ];
  });

  return byStatus;
};

const getValueRangeItem = (value, item, obsRange) => {
  let color;
  let colorCode;
  let icon;
  let text;
  obsRange?.forEach(
    ({
      range,
      color: rangeColor,
      colorCode: rangeColorCode,
      icon: rangeIcon,
      text: rangeText,
    }) => {
      if (
        Number(value) >= Number(range[0]) &&
        Number(value) <= Number(range[1])
      ) {
        colorCode = rangeColorCode;
        color = rangeColor;
        icon = rangeIcon;
        text = rangeText;
      }
    }
  );
  if (item === 'text') return text;
  if (item === 'icon') return icon;
  if (item === 'colorCode') return colorCode;

  if (color === 'blue') return '#5076ff';
  if (color === 'green') return '#00C48C';
  if (color === 'greenish') return '#0e8301';
  if (color === 'greenish2') return '#12b001';
  if (color === 'yellow') return '#FFD443';
  if (color === 'orange') return ' rgba(255, 175, 62, 1)';
  if (color === 'red') return '#FF6666';
  if (color === 'red2') return '#ff3a3a';
  return color || 'black';
};

function determineStatus(ranges, value, isAbnormal) {
  const numericValue = parseFloat(value);
  if (!ranges.length && isAbnormal) {
    return 'Abnormal';
  }
  for (const range of ranges) {
    const start = parseFloat(range.values[0]);
    const end = parseFloat(range.values[1]);

    if (numericValue >= start && numericValue < end) {
      return range.status;
    }
    const splitValue = value?.split(' ');
    if (
      splitValue?.length > 1 &&
      splitValue[1] >= start &&
      splitValue[1] < end
    ) {
      return range.status;
    }
  }

  return 'Normal';
}



export {
  convertToDisplayedObservation,
  convertListOfObservations,
  sortByStatus,
  observationsByCat,
  mapObservationsByStatus,
  convertAnomalyResult,
  sortOrder,
  getObservationRange,
  getValueRangeItem,
  determineStatus,
};
