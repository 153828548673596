import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import CircleLoader from 'react-spinners/CircleLoader';
import cx from 'classnames';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

import { Checkbox, Icon, Popup, Radio } from 'semantic-ui-react';
import {
  ErrorToast,
  Input,
  Search,
  Select,
  Text,
  Accordion,
  Button,
  SuccessToast,
  Cleave,
  TextArea,
} from 'components';

import {
  AUTO_DATE,
  AUTO_TIME,
  CHOICES_INPUT_TYPES,
  CLINICIAN_DETS,
  DATE_INPUT_TYPES,
  DO_NOT_DISPLAY,
  MULTIPLE_CHOICES,
  OTHER_INPUT_TYPES,
  PAT_MEDS,
} from 'utils/quest';

import MedicalService from 'api/patientOverview/medicalProfileApi';
import PatientService from 'api/patient/patientApi';
import history from '../../../../history';

import './questionnaire.scss';

const PublicQuestionnaire = () => {
  const params = new URLSearchParams(useLocation().search);

  const [clientId, setClientId] = useState(params?.get('clientId'));
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const [searchValue, setSearchValue] = useState('');

  const [onViewMode, setViewMode] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [edit, setEdit] = useState(false);

  const [questionnaire, setQuestionnaire] = useState([]);
  const [allQestions, setQuestions] = useState([]);
  const [sections, setSections] = useState([]);
  const [answers, setAnswers] = useState({});
  const [errors, setErrors] = useState({});

  const oldAnswers = {};
  const isEditting = false;

  useEffect(() => {
    fetchQuestionnaire();
  }, [clientId]);

  useEffect(() => {
    checkAndSetRequired();
  }, [answers]);

  const fetchQuestionnaire = async () => {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await MedicalService.getPublicQuestionnaire(
        'mental-health-peme',
        clientId
      );
      setQuestions(data?.questions);
      groupQuestionnaire(data.questions);
    } catch (error) {
      console.log(error);
      toast.error(<ErrorToast error={error.message} />);
    } finally {
      setIsLoading(false);
    }
  };

  const groupQuestionnaire = (data) => {
    const groupedItems = data.reduce((acc, item) => {
      const category = item.category;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(item);
      return acc;
    }, {});
    const cats = Object.keys(groupedItems);
    setSections(cats);
    setQuestionnaire(groupedItems);
    console.log(groupedItems);
  };

  const indexToAlphabet = (index, catId) => {
    if (!['F4-general-information', 'F4-questionnaire'].includes(catId))
      return '';
    return String.fromCharCode(65 + index) + '.';
  };

  const onAnswerSelected = (id, text, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [id]: { value, text: text ? text : null },
    }));
  };

  const onAdditionalInfo = (id, text) => {
    const res = answers[id];
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [id]: { ...res, additionalInfo: text },
    }));
  };

  const isAnswerChecked = (id, choice) => {
    const isChecked = answers[id]?.find((itm) => itm?.value === choice?.value);
    return Boolean(isChecked);
  };

  const renderOptions = (options) => {
    return options?.map((opt) => ({
      text: opt.text,
      value: opt.value,
      key: opt?._id,
    }));
  };

  const displaySubtext = (text) => {
    if (!text) return '';
    const sub = text.split('-');
    if (sub.length > 1) {
      return (
        <ul className="quetionnaire-document__bullet-list">
          {sub
            .filter((item) => item && item.trim() !== '')
            .map((item, index) => (
              <li key={index}>{item}</li>
            ))}
        </ul>
      );
    }
    return (
      <ul className="quetionnaire-document__bullet-list">
        <li> {text}</li>
      </ul>
    );
  };

  const checkAndSetRequired = () => {
    const result = [];
    allQestions.forEach((quest, index) => {
      quest.hide = false;
      if (answers['F4-Q14']?.value === 1 && quest?.friendlyId === 'F4-Q15') {
        quest.isRequired = true;
        quest.hide = false;
      }
      if (answers['F4-Q14']?.value === 2 && quest?.friendlyId === 'F4-Q15') {
        quest.isRequired = false;
        quest.hide = true;
      }
      if (answers['F4-Q16']?.value === 1 && quest?.friendlyId === 'F4-Q17') {
        quest.isRequired = true;
        quest.hide = false;
      }
      if (answers['F4-Q16']?.value === 2 && quest?.friendlyId === 'F4-Q17') {
        quest.isRequired = false;
        quest.hide = true;
      }
      result.push(quest);
    });
    setQuestions(result);
  };

  const isFormValid = () => {
    let isValid = true;
    const newErrors = {};
    const errMsg = 'This field is required';

    allQestions.forEach((question) => {
      if (
        (question?.isRequired && !answers[question?.friendlyId]) ||
        answers[question?.friendlyId]?.text === ''
      ) {
        isValid = false;
        newErrors[question?.friendlyId] = {
          errMsg,
          category: question?.category,
        };
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const onSubmitAnswers = async () => {
    if (Object.keys(answers)?.length === 0) {
      return toast.error(
        <ErrorToast
          error={'You cannot submit an empty form. Kindly answer the questions'}
        />
      );
    }

    if (!isFormValid()) {
      const keys = Object.keys(errors);
      const keyAtIndex = keys[0];
      const cat = errors[keyAtIndex]?.category;
      return toast.error(
        <ErrorToast
          error={`Fill all required fields under the ${cat} section`}
        />
      );
    }

    try {
      setSaving(true);
      const formatedAnswers = [];
      Object.entries(answers).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          formatedAnswers.push({
            questionFriendlyId: key,
            multipleChoices: value,
          });
        } else {
          formatedAnswers.push({
            questionFriendlyId: key,
            ...value,
          });
        }
      });

      const dataBody = {
        templateId: 'mental-health-peme',
        answers: formatedAnswers,
      };
      await MedicalService.createPublicAnswers(dataBody, clientId);
      // toast.success(<SuccessToast message={'Answers have been saved'} />);
      history.push({
        pathname: '/public/health-questionnaire/success',
      });
      setAnswers({});
    } catch (error) {
      console.log(error);
      toast.error(<ErrorToast error={'Failed to save'} />);
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="quetionnaire-document quetionnaire-document__public">
      <div className="issued-certificates__header mt-4">
        <Text type="header" bold>
          Mental Health PEME
        </Text>
      </div>
      {isLoading || questionnaire.length === 0 ? (
        <div className="quetionnaire-document__loader">
          <CircleLoader color="#455af7" />
        </div>
      ) : (
        <div className="quetionnaire-document__main-wrapper">
          {sections?.map((section, index) => (
            <Accordion
              id={index}
              headerText={section}
              open={activeIndex === index}
            >
              <Text className="mt-2 ml-2" dark size="medium">
                {section.split('-')[1]}
              </Text>

              {questionnaire[section][0]?.description !== '' && (
                <i style={{ fontSize: '14px' }}>
                  {questionnaire[section][0]?.description || ''}
                </i>
              )}

              {section !== 'Medications' && (
                <div className="quetionnaire-document__grid">
                  {questionnaire[section]
                    ?.filter((item) => Boolean(!item?.hide))
                    .map((quest, index) => (
                      <div className="quetionnaire-document__grid__column">
                        <div className="quetionnaire-document__grid__row">
                          <Text bold className="mr-2">
                            {indexToAlphabet(index, quest?.categoryId)}
                          </Text>
                          <Text>
                            {quest.text}
                            {quest?.isRequired && (
                              <span className="quetionnaire-document__asterisk">
                                *
                              </span>
                            )}
                            {quest?.subText && (
                              <Popup
                                content={
                                  <i style={{ fontSize: '12px' }}>
                                    {displaySubtext(quest?.subText) || ''}
                                  </i>
                                }
                                mouseEnterDelay={500}
                                mouseLeaveDelay={500}
                                on="hover"
                                trigger={
                                  <Icon
                                    circular
                                    size="tiny"
                                    name="info"
                                    className="ml-3"
                                    style={{ marginLeft: '10px' }}
                                  />
                                }
                              />
                            )}
                          </Text>
                        </div>
                        {CHOICES_INPUT_TYPES.includes(quest?.type) && (
                          <div className="quetionnaire-document__grid__column ml-4 mt-4">
                            {quest?.choices?.map((choice, idx) => (
                              <div className="quetionnaire-document__grid__row ">
                                <Radio
                                  id={choice?._id}
                                  className="mt-3"
                                  key={choice?._id}
                                  label={
                                    !choice?.needAdditionalInfo
                                      ? choice?.text
                                      : ''
                                  }
                                  checked={
                                    answers[quest?.friendlyId]?.value ===
                                    choice?.value
                                  }
                                  onChange={(a, { value }) =>
                                    onAnswerSelected(
                                      quest?.friendlyId,
                                      choice?.text,
                                      value
                                    )
                                  }
                                  value={choice?.value}
                                  disabled={onViewMode}
                                />
                                {choice?.needAdditionalInfo &&
                                  displayOptionsLabel(
                                    choice,
                                    quest?.friendlyId
                                  )}
                                {OTHER_INPUT_TYPES.includes(choice?.text) && (
                                  <Input
                                    key={quest?._id}
                                    placeholder="answer here"
                                    size={'small'}
                                    value={
                                      choice?.text ===
                                      answers[quest?.friendlyId]?.text
                                        ? answers[quest?.friendlyId]
                                            ?.additionalInfo
                                        : ''
                                    }
                                    onChange={(e) =>
                                      onAdditionalInfo(
                                        quest?.friendlyId,
                                        e?.target?.value
                                      )
                                    }
                                    className="ml-2 quetionnaire-document__additonalInfo-input"
                                    disabled={onViewMode}
                                    invalid={
                                      answers[quest?.friendlyId]?.value ===
                                        choice?.value &&
                                      !answers[quest?.friendlyId]
                                        ?.additionalInfo
                                    }
                                    showErrorMessage={false}
                                  />
                                )}
                              </div>
                            ))}

                            {quest?.type === 'choiceAndText' && (
                              <Input
                                key={quest?._id}
                                placeholder="type answer here"
                                size="small"
                                type="number"
                                value={
                                  quest?.type === 'choiceAndText'
                                    ? answers[quest?.friendlyId]?.additionalInfo
                                    : ''
                                }
                                onChange={(e) =>
                                  onAdditionalInfo(
                                    quest?.friendlyId,
                                    e?.target?.value
                                  )
                                }
                                className="ml-2"
                                disabled={onViewMode}
                                invalid={errors[quest?.friendlyId]?.errMsg}
                                showErrorMessage={false}
                              />
                            )}
                            {errors[quest?.friendlyId] && (
                              <Text size="tiny" color="red">
                                {errors[quest?.friendlyId]?.errMsg}
                              </Text>
                            )}
                          </div>
                        )}
                        {MULTIPLE_CHOICES.includes(quest?.type) && (
                          <div className="quetionnaire-document__grid__column ml-4 mt-4">
                            {quest?.choices?.map((choice, idx) => (
                              <div className="quetionnaire-document__grid__row ">
                                <Checkbox
                                  className="mt-3"
                                  key={choice?._id}
                                  label={choice?.text}
                                  checked={isAnswerChecked(
                                    quest?.friendlyId,
                                    choice
                                  )}
                                  onChange={(a, { value, checked }) =>
                                    onSelectMultipleChoices(
                                      quest?.friendlyId,
                                      choice,
                                      checked
                                    )
                                  }
                                  value={choice?.value}
                                  readOnly={onViewMode}
                                />
                                {OTHER_INPUT_TYPES.includes(choice?.text) && (
                                  <Input
                                    key={quest?._id}
                                    placeholder="type answer here"
                                    size="small"
                                    value={
                                      OTHER_INPUT_TYPES.includes(
                                        answers[quest?.friendlyId]?.text
                                      )
                                        ? answers[quest?.friendlyId]
                                            ?.additionalInfo
                                        : ''
                                    }
                                    onChange={(e) =>
                                      onAdditionalInfo(
                                        quest?.friendlyId,
                                        e?.target?.value
                                      )
                                    }
                                    className="ml-2"
                                    disabled={onViewMode}
                                  />
                                )}
                              </div>
                            ))}
                            {errors[quest?.friendlyId] && (
                              <Text size="tiny" color="red">
                                {errors[quest?.friendlyId]?.errMsg}
                              </Text>
                            )}
                          </div>
                        )}
                        {quest?.type === 'text' &&
                          !PAT_MEDS.includes(quest?.friendlyId) && (
                            <div className="ml-4 mt-4">
                              <TextArea
                                key={quest?._id}
                                placeholder="type answer here"
                                size="smedium"
                                value={answers[quest?.friendlyId]?.text || ''}
                                onChange={(e) =>
                                  onAnswerSelected(
                                    quest?.friendlyId,
                                    e?.target?.value,
                                    null
                                  )
                                }
                                disabled={onViewMode}
                                invalid={errors[quest?.friendlyId]}
                                showErrorMessage={false}
                                className="quetionnaire-document__textArea-fontsize"
                                style={{ width: '240px' }}
                              />
                            </div>
                          )}
                        {quest?.type === 'number' && (
                          <div className="ml-4 mt-4">
                            <Input
                              key={quest?._id}
                              placeholder="type answer here"
                              size="smedium"
                              type="number"
                              value={answers[quest?.friendlyId]?.text || ''}
                              onChange={(e) =>
                                onAnswerSelected(
                                  quest?.friendlyId,
                                  e?.target?.value,
                                  null
                                )
                              }
                              disabled={onViewMode}
                              invalid={errors[quest?.friendlyId]}
                              showErrorMessage={false}
                              style={{ fontSize: '14px', width: '240px' }}
                            />
                          </div>
                        )}
                        {DATE_INPUT_TYPES.includes(quest?.type) && (
                          <div className="ml-4 mt-4">
                            <Cleave
                              style={{ width: '150px' }}
                              size="small"
                              placeholder={
                                quest?.type === 'hour' ? '00:00' : 'DD-MM-YYYY'
                              }
                              options={
                                quest?.type === 'hour'
                                  ? {
                                      time: true,
                                      timePattern: ['h', 'm'],
                                    }
                                  : {
                                      date: true,
                                      datePattern: ['d', 'm', 'Y'],
                                      delimiter: '-',
                                    }
                              }
                              onChange={(e) =>
                                onAnswerSelected(
                                  quest?.friendlyId,
                                  e?.target?.value,
                                  null
                                )
                              }
                              value={answers[quest?.friendlyId]?.text || ''}
                              disabled={onViewMode}
                              invalid={errors[quest?.friendlyId]}
                              showErrorMessage={false}
                            />
                          </div>
                        )}
                        {quest?.type === 'dropDown' &&
                          !DO_NOT_DISPLAY.includes(quest?.friendlyId) && (
                            <div className="ml-4 mt-4">
                              <Select
                                border
                                placeholder="select one"
                                value={answers[quest?.friendlyId]?.value || ''}
                                onChange={(e, { value }) =>
                                  onAnswerSelected(
                                    quest?.friendlyId,
                                    quest?.choices[value - 1].text,
                                    value
                                  )
                                }
                                options={renderOptions(quest?.choices)}
                                search
                                size="small"
                                selection
                                className="quetionnaire-document__select"
                                disabled={onViewMode}
                                invalid={errors[quest?.friendlyId]}
                                showErrorMessage={false}
                              />
                            </div>
                          )}
                      </div>
                    ))}
                </div>
              )}
            </Accordion>
          ))}
          {sections.length > 0 && (
            <div className="quetionnaire-document__grid__row">
              {edit && isEditting && !isPastEditing && (
                <>
                  <Button size="wide" onClick={() => onEditClick()} color="red">
                    Cancel
                  </Button>
                  <Button
                    size="fullWidth"
                    onClick={() => onSubmitAnswers()}
                    color="blue"
                    isLoading={isSaving}
                    className={cx({ 'ml-2': edit })}
                  >
                    Update
                  </Button>
                </>
              )}
              {!onViewMode && !oldAnswers?.length && !isEditting && (
                <Button
                  size="fullWidth"
                  onClick={() => onSubmitAnswers()}
                  color="blue"
                  isLoading={isSaving}
                >
                  Submit
                </Button>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PublicQuestionnaire;
